import React, { useState, useEffect } from "react";
import Group from "../images/Group.png";
import moneycheck from "../images/moneycheck.png";
import mastercard from "../images/mastercard.png";

const Table: React.FC = () => {


  return (
    <>
      <div className="payment-confirmation">
        {<p className="mainheading">Choose a plan for your business</p>}

        <div style={{ display: "flex", paddingLeft: "40px" }}>
          <img
            src={Group}
            alt="paymentconfirm"
            style={{
              marginBottom: "10px",
              width: "400px",
              height: "255px",
              paddingTop: "40px",
            }}
          />
        </div>

        <div
          style={{ display: "flex", paddingLeft: "40px", marginTop: "40px" }}
        >
          <img
            src={moneycheck}
            alt="paymentconfirm"
            style={{ width: "30px", height: "25px" }}
          ></img>{" "}
          &nbsp;&nbsp;
          <h4
            style={{
              color: "#150054",
              fontSize: "13px",
              fontWeight: "bold",
              paddingTop: "4px",
            }}
          >
            Plan and Payment
          </h4>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ display: "grid", paddingLeft: "40px" }}>
            <h6 className="pc">Subscribed To:</h6>
            <h6 className="pc">Payment Method:</h6>
            <h6 className="pc">Billing Cycle:</h6>
          </div>

          <div style={{ display: "grid", paddingLeft: "40px" }}>
            <h6 className="pcr " style={{ marginTop: "21px" }}>
              Datatram Standard Package - $50/year
              <br />
              <div style={{ display: "flex" }}>
                {" "}
                <i
                  className="far fa-arrow-alt-circle-up"
                  style={{ color: "#38ABD2", marginTop: "2px" }}
                ></i>
                <p
                  style={{
                    color: "#38ABD2",
                    textAlign: "left",
                    paddingLeft: "4px",
                  }}
                >
                  Upgrade
                </p>
              </div>
            </h6>

            <div className="payment_method " style={{ marginTop: "20px" }}>
              <img
                src={mastercard}
                alt="paymentconfirm"
                style={{ width: "35px", height: "35px", marginTop: "-20px" }}
              ></img>{" "}
              &nbsp;&nbsp;
              <h6 className="pcr" style={{ marginTop: "-10px" }}>
                ending in 0000
              </h6>
            </div>

            <h6 className="pcr" style={{ marginTop: "25px" }}>
              $50 on February 6, 2023
              <br />
              Next payment on: February 6, 2024
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
