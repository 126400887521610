import React, { useState, useEffect } from "react";
import DpLogo from "../../images/DpLogo.png";
import NewEmail from "../../images/NewEmail.png";
import OtpInput from "react-otp-input";

interface OtpProperties {
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
}

const Otp: React.FC = () => {
  const [otp, setOtp] = useState("");



  return (
    <>
      <div className="body noScroll">
        <img src={DpLogo} className="logo-design" alt="img" />
        <div className="app">
          <form
            className="formdesign"
          //  onSubmit={handleSubmit}
          >
            <div className="items-center">
              <img src={NewEmail} className="new-email" alt="NewEmail" />
              <h2 className="entercode">Enter The Code</h2>
              <p className="Otp-Detail">
                A verification email has been sent to your email address. Please
                enter the code below to continue.
              </p>
              <div>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span style={{ color: "#38ABD2" }}>-</span>}
                  renderInput={(props, index) => (
                    <div className="otp-block-row">
                      <input {...props} className="otp-boxes" />
                    </div>
                  )}
                />
              </div>

              <button className="mt-5">Verify Account</button>
              <p className="resend-email">
                Didn’t receive the email?&nbsp;
                <a href="#">Resend</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Otp;
