import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.css";
import SignUp from "./components/SignUp/SignUp";
import Otp from "./components/Otp/Otp";
import Resetpassword from "./components/ResetPassword/Resetpassword";
import Confirmresetpassword from "./components/Confirmresetpassword/Confirmresetpassword";
import Dashboard from "./components/Dashboard/Dashboard";
import Sidebar from "./components/Sidebar/Sidebar";
import PrivacyPolicy from "./components/PrivacyPolicy/privacy-policy";
import MagicScreen from "./components/MagicScreen";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route path="/WelcomeToDatatram" element={<Sidebar />} />
        <Route path="/Otp" element={<Otp />} />
        <Route path="/Resetpassword" element={<Resetpassword />} />
        <Route
          path="/Confirmresetpassword"
          element={<Confirmresetpassword />}
        />

        <Route path="/WelcomeToDatatram/Analytics" element={<Dashboard />} />
        <Route path="/WelcomeToDatatram/loading" element={<MagicScreen />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};
export default App;
