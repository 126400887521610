import React, { useEffect, useState } from "react";
import standardicon from "../images/standardicon.svg";
import Pro from "../images/Pro.svg";
import PaymentModal from "./PaymentModal";
import greentick from "../images/greentick.svg";
import Premium from "../images/Premium.svg";
import Circle from "../images/circle.svg";

interface ComparePlanProps {
  onBack: () => void;
  payNowClicked: any;
}

const ComparePlan: React.FC<ComparePlanProps> = ({ onBack, payNowClicked }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);



  return (
    <>
      <PaymentModal
        isVisible={isModalVisible}
        onClose={handleCancel}
        onNext={() => { }}
        payNowClicked={payNowClicked}
      />
      <p className="mainheading">Choose a plan for your business</p>
      <div>
        <span className="planpage_switch_link" onClick={onBack}>
          Plans Overview
        </span>
      </div>

      <div className="compare-container ">
        <div className="row colHeadings">
          <div className="col-3"></div>
          <div className="col-3 heading-icon-container">
            <img
              src={standardicon}
              className="imagestyle"
              alt="Standard Plan"
            />
            <div className="heading-price-container">
              <h3 className="plan-name">Standard</h3>
              <h1 className="prices">$50</h1>
              <button className="subscribecmp" onClick={showModal}>
                SUBSCRIBE
              </button>
            </div>
          </div>
          <div className="col-3 heading-icon-container">
            <img src={Pro} className="imagestyle" alt="Pro Plan" />
            <div className="heading-price-container">
              <p className="plan-name">Pro</p>
              <h1 className="prices">$100</h1>
              <button className="subscribecmp" onClick={showModal}>
                SUBSCRIBE
              </button>
            </div>
          </div>
          <div className="col-3 heading-icon-container">
            <img src={Premium} className="imagestyle" alt="Premium Plan" />
            <div className="heading-price-container">
              <p className="plan-name">Premium</p>
              <h1 className="prices">$250</h1>
              <button className="subscribecmp" onClick={showModal}>
                SUBSCRIBE
              </button>
            </div>
          </div>

          <div className="row tableBody mt-2">
            <div className="row">
              <div className="col-3">
                <p className="rowHeadings ">Users</p>
              </div>

              <div className="col-3">
                <p>5 users included</p>
              </div>
              <div className="col-3 ">
                <p>10 users included</p>
              </div>
              <div className="col-3">
                <p>15 users included</p>
              </div>
            </div>

            <div className="row greyrow ">
              <div className="col-3">
                <p className="rowHeadings ">Connectors</p>
              </div>
              <div className="col-3">
                <p>5 Connectors (Facebook Ad, Google Ad, etc.)</p>
              </div>
              <div className="col-3">
                <p>7 Connectors (Standard + Tiktok + Snapchat)</p>
              </div>
              <div className="col-3">
                <p>7 Connectors (Standard + Tiktok + Snapchat)</p>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="rowHeadings ">Refresh Rate</p>
              </div>
              <div className="col-3">
                <p>Daily, weekly, monthly</p>
              </div>
              <div className="col-3 ">
                <p>Hourly, daily, weekly, monthly</p>
              </div>
              <div className="col-3">
                <p>Hourly, daily, weekly, monthly</p>
              </div>
            </div>

            <div className="row greyrow ">
              <div className="col-3">
                <p className="rowHeadings ">Historical Data</p>
              </div>
              <div className="col-3">
                <p>
                  {" "}
                  <img src={Circle} alt="circle" />
                </p>
              </div>
              <div className="col-3">
                <p>3 months</p>
              </div>
              <div className="col-3">
                <p>3, 6, 12 months</p>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="rowHeadings ">Visualization</p>
              </div>
              <div className="col-3">
                <p>Auto-generated dashboards - Metabase</p>
              </div>
              <div className="col-3">
                <p>Auto-generated dashboards - Power BI</p>
              </div>
              <div className="col-3">
                <p>Activity log + insights (In-app, email, Slack)</p>
              </div>
            </div>

            <div className="row greyrow ">
              <div className="col-3">
                <p className="rowHeadings ">Scheduled Reports</p>
              </div>
              <div className="col-3">
                <p>
                  {" "}
                  <img src={Circle} alt="circle" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={greentick} alt="tick" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={greentick} alt="tick" />
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="rowHeadings ">User Roles & Permissions</p>
              </div>
              <div className="col-3">
                <p>
                  <img src={Circle} alt="circle" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={greentick} alt="tick" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={greentick} alt="tick" />
                </p>
              </div>
            </div>

            <div className="row greyrow ">
              <div className="col-3">
                <p className="rowHeadings ">Alerts</p>
              </div>
              <div className="col-3">
                <p>Activity log (In-app, email)</p>
              </div>
              <div className="col-3">
                <p>Activity log + insights (In-app, email, Slack)</p>
              </div>
              <div className="col-3">
                <p> Activity log + insights (In-app, email, Slack)</p>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="rowHeadings ">Support</p>
              </div>
              <div className="col-3">
                <p>In-app</p>
              </div>
              <div className="col-3">
                <p>In-app</p>
              </div>
              <div className="col-3">
                <p>
                  In-app + priority response + Expert Consultation (2 hrs/month)
                </p>
              </div>
            </div>

            <div className="row greyrow ">
              <div className="col-3">
                <p className="rowHeadings ">Customer Success Partner</p>
              </div>
              <div className="col-3">
                <p>
                  <img src={Circle} alt="circle" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={Circle} alt="circle" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={greentick} alt="tick" />
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="rowHeadings ">Dark Mode</p>
              </div>
              <div className="col-3">
                <p>
                  <img src={Circle} alt="circle" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={greentick} alt="tick" />
                </p>
              </div>
              <div className="col-3">
                <p>
                  <img src={greentick} alt="tick" />
                </p>
              </div>
            </div>

            <div className="row greyrow ">
              <div className="col-3">
                <p className="rowHeadings ">Security</p>
              </div>
              <div className="col-3">
                <p>ISO 27001</p>
              </div>
              <div className="col-3">
                <p>ISO 27001</p>
              </div>
              <div className="col-3">
                <p>ISO 27001</p>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="rowHeadings ">Setup</p>
              </div>
              <div className="col-3">
                <p>Self-service setup</p>
              </div>
              <div className="col-3">
                <p>Self-service setup</p>
              </div>
              <div className="col-3">
                <p>Self-service setup</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparePlan;
