import React, { useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import standardicon from "../images/standardicon.svg";
import Pro from "../images/Pro.svg";
import Premium from "../images/Premium.svg";
import users from "../images/users.png";
import chart from "../images/chart.png";
import refresh from "../images/refresh.png";
import history from "../images/history.png";
import network from "../images/network.png";
import PaymentModal from "./PaymentModal";
import ComparePlan from "./ComparePlans";

interface PlanOverviewProps {
  onBack: () => void;
  payNowClicked: any;
}

const PlansOverview: React.FC<PlanOverviewProps> = ({
  onBack,
  payNowClicked,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showComparePlan, setShowComparePlan] = useState(false);
  const toggleModal = () => setIsModalVisible(!isModalVisible);
  const toggleComparePlan = () => setShowComparePlan(!showComparePlan);



  return (
    <>
      {showComparePlan ? (
        <ComparePlan onBack={toggleComparePlan} payNowClicked={payNowClicked} />
      ) : (
        <>
          <div style={{ display: "grid" }}>
            <PaymentModal
              isVisible={isModalVisible}
              onClose={toggleModal}
              onNext={() => { }}
              payNowClicked={payNowClicked}
            />
            <p className="mainheading">Choose a plan for your business</p>
            <span className="planpage_switch_link " onClick={toggleComparePlan}>
              Compare Plans
            </span>
            <div className="d-flex justify-content-center mx-2">
              <button className="monthlybtn">Monthly</button>
              <button className="annualbtn">Annually</button>
            </div>
            <div className="over-flow-y">
              <Row>

                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <div className="zoomable-div">
                    <div style={{ display: "grid", placeItems: "center" }}>
                      <img
                        src={standardicon}
                        className="plan-over-icon "
                        alt="Standard Plan"
                      />
                      <h3 className="standard">Standard</h3>
                      <p className="prices">$50</p>
                    </div>
                    <Divider className="Divider" />
                    <div style={{ display: "grid", placeItems: "center" }}>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        <li>
                          <img src={users} alt="users" /> &nbsp;5 users included
                        </li>
                        <li>
                          <img src={network} alt="connectors" /> &nbsp;5
                          connectors
                        </li>
                        <li>
                          <img src={refresh} alt="refresh" /> &nbsp;Daily, Weekly,
                          Monthly refresh rate
                        </li>
                        <li>
                          <img src={history} alt="history" /> &nbsp;No historical
                          data
                        </li>
                        <li>
                          <img src={chart} alt="dashboards" />{" "}
                          &nbsp;Auto-generated dashboards (Metabase)
                        </li>
                      </ul>
                      <button className="subscribecmp" onClick={toggleModal}>
                        SUBSCRIBE
                      </button>
                    </div>
                  </div>
                </Col>


                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <div className="zoomable-div">
                    <div style={{ display: "grid", placeItems: "center" }}>
                      <img src={Pro} className="plan-over-icon" alt="Pro Plan" />
                      <h3 className="standard">Pro</h3>
                      <p className="prices">$100</p>
                    </div>
                    <Divider className="Divider" />
                    <div style={{ display: "grid", placeItems: "center" }}>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        <li>
                          <img src={users} alt="users" /> &nbsp;10 users included
                        </li>
                        <li>
                          <img src={network} alt="connectors" /> &nbsp;7
                          connectors
                        </li>
                        <li>
                          <img src={refresh} alt="refresh" /> &nbsp;Hourly, Daily,
                          Weekly, Monthly refresh rate
                        </li>
                        <li>
                          <img src={history} alt="history" /> &nbsp;3 months
                          historical data
                        </li>
                        <li>
                          <img src={chart} alt="dashboards" />{" "}
                          &nbsp;Auto-generated dashboards (PowerBI)
                        </li>
                      </ul>
                      <button className="subscribecmp" onClick={toggleModal}>
                        SUBSCRIBE
                      </button>
                    </div>
                  </div>
                </Col>


                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <div className="zoomable-div">
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <img
                        src={Premium}
                        className="plan-over-icon"
                        alt="Premium Plan"
                      />
                      <h3 className="standard">Premium</h3>
                      <p className="prices">$250</p>
                    </div>
                    <Divider className="custom-divider" />
                    <div style={{ display: "grid", placeItems: "center" }}>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        <li>
                          <img src={users} alt="users" /> &nbsp;15 users included
                        </li>
                        <li>
                          <img src={network} alt="connectors" /> &nbsp;7
                          connectors (Same as Pro)
                        </li>
                        <li>
                          <img src={refresh} alt="refresh" /> &nbsp;Hourly, Daily,
                          Weekly, Monthly refresh rate
                        </li>
                        <li>
                          <img src={history} alt="history" /> &nbsp;6 months
                          historical data
                        </li>
                        <li>
                          <img src={chart} alt="dashboards" />{" "}
                          &nbsp;Auto-generated dashboards (Tableau)
                        </li>
                      </ul>
                      <button className="subscribecmp" onClick={toggleModal}>
                        SUBSCRIBE
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlansOverview;
