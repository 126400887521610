import React, { useState, useEffect, Fragment } from "react";
import Girl from "../images/Girl.png";
import { Col, Row } from "antd";

interface UserIntroProps {
  onNext: (isValid: boolean) => void;
}

const UserIntro: React.FC<UserIntroProps> = ({ onNext }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [companyError, setCompanyError] = useState<string>("");

  const handleValidation = () => {
    setFirstNameError("");
    setLastNameError("");
    setCompanyError("");

    let isValid = true;

    if (firstName.trim() === "") {
      setFirstNameError("First Name is required *");
      isValid = false;
    }

    if (lastName.trim() === "") {
      setLastNameError("Last Name is required *");
      isValid = false;
    }

    if (company.trim() === "") {
      setCompanyError("Company is required *");
      isValid = false;
    }

    if (isValid) {
      onNext(true);
    }
  };



  return (
    <Fragment>

      <h2 className="h2design">Tell us a bit more about yourself</h2>
      <Row>
        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
          <div className="form-section">
            <div className="input-container">
              <label>
                First Name
                <input
                  required
                  value={firstName}
                  className="input-style"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <div className="error-message">{firstNameError}</div>
              </label>
            </div>

            <div className="input-container">
              <label>
                Last Name
                <input
                  className="input-style"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <div className="error-message">{lastNameError}</div>
              </label>
            </div>

            <div className="input-container">
              <label>
                Your Company
                <input
                  required
                  className="input-style"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <div className="error-message">{companyError}</div>
              </label>
            </div>
          </div>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <div className="image-section">
            <img src={Girl} alt="Girl" className="girl" />
          </div>
        </Col>
      </Row>




      <button
        id="nextBtn"
        onClick={handleValidation}
        style={{ display: "none" }}
      >
        Next
      </button>
    </Fragment>
  );
};

export default UserIntro;
