// src/components/GoogleAnalyticsModal.tsx
import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import InstagramIcon from "./Images/instagram.png"; // Adjust the path as needed

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnected: () => void;
}

const InstagramModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConnected,
}) => {
  const [store, setStore] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [authentication, setAuthentication] = useState<string>(
    "Admin API Access Token"
  );

  if (!isOpen) return null;
  const instaConnected = () => {
    // Perform your connection logic here
    onConnected();
  };

  return (
    <div className="source-modal">
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
            type="button"
            className="btn-close close-btn"
            onClick={onClose}
          ></button>
          <div className="innerbody">
            <img id="Instagram-logo" src={InstagramIcon} alt="Instagram Icon" />

            {/* <div className="shopify-signin">
              <div className="form-selection">
                <label htmlFor="store" className="storeOne">
                  Store
                </label>
                <input
                  type="text"
                  id="store"
                  className="form-control"
                  placeholder="Enter store URL"
                  value={store}
                  onChange={(e) => setStore(e.target.value)}
                />
              </div>

              <button
                id="login-via-shopify"
                type="button"
                onClick={() =>
                  window.open(
                    "https://www.shopify.com/store-login",
                    "_blank",
                    "width=500,height=400"
                  )
                }
                className="shopify-signin-btn btn btn-secondary"
              >
                Continue with Shopify
              </button>
            </div> */}

            <div className="form-input">
              <label htmlFor="authentication">Authentication</label>
              {/* Bootstrap dropdown for authentication methods */}
              <select
                id="authentication"
                className="form-select"
                value={authentication}
                onChange={(e) => setAuthentication(e.target.value)}
              >
                <option value="Admin API Access Token">
                  Admin API Access Token
                </option>
                <option value="OAuth 2.0">OAuth 2.0</option>
              </select>

              <div className="form-input mt-3">
                <label htmlFor="accessToken">Access Token</label>
                <input
                  type="text"
                  id="accessToken"
                  className="form-control"
                  placeholder="Enter your access token"
                  value={accessToken}
                  onChange={(e) => setAccessToken(e.target.value)}
                />
              </div>
            </div>

            <div className="button-group">
              <button className="create-connection">Create Connection</button>
              <button className="cancel" onClick={onClose}>
                Cancel
              </button>
              <button className="test-connection">Test Connection</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramModal;
