import React, { useState } from "react";
import {
  BellFilled,
  UserOutlined,
  SearchOutlined,
  AppstoreOutlined,
  ApiOutlined,
  SettingOutlined,
  TeamOutlined,
  CustomerServiceOutlined,
  CreditCardOutlined,
  IdcardOutlined,
  BellOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import { Input } from "antd";
import Dashboard from "../../Icons/Dashboard.png";
import user from "../../Icons/user.png";
import usergear from "../../Icons/usergear.png";
import plug from "../../Icons/plug.png";
import users from "../../Icons/users.png";
import headset from "../../Icons/headset.png";
import creditcardfront from "../../Icons/creditcardfront.png";
import fileuser from "../../Icons/fileuser.png";
import bells from "../../Icons/bells.png";
import { Layout, Menu, theme } from "antd";
import SocialIcons from "../SourceConnector/Source-Connector-Screen";

const { Header, Sider, Content } = Layout;
const { Item, SubMenu } = Menu;

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");

  const handleMenuClick = (key) => {
    setSelectedMenuItem(key);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleSubMenuHover = () => {
    if (collapsed) {
      setCollapsed(false);
    }
  };

  const handleSubMenuLeave = () => {
    if (!collapsed) {
      setCollapsed(true);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          backgroundColor: "#2B68AD",
          position: "fixed",
          height: "100%",
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          onMouseEnter={handleSubMenuHover}
          onMouseLeave={handleSubMenuLeave}
          theme="#2B68AD"
          mode="inline"
          color="white"
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedMenuItem]}
          onClick={({ key }) => handleMenuClick(key)}
          style={{ backgroundColor: "#2B68AD", color: "white" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="32"
            className="dt"
            style={{ margin: "5px auto", marginTop: "20px", display: "block" }}
            viewBox="0 0 35 32"
            fill="none"
            onClick={() => setCollapsed(!collapsed)}
          >
            <path
              d="M1 30.5C12.2113 27.974 5.52192 2.0716 17 1.5C28.5918 0.922731 22.2352 27.7056 33.5 30.5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>

          <hr
            style={{
              width: "170px",
              margin: "11px auto",
              height: "1px",
              opacity: "0.3",
            }}
          />

          <SubMenu
            key="1"
            title="Dashboards"
            icon={
              <AppstoreOutlined
                style={{
                  fontSize: "17px",
                  marginLeft: collapsed ? "1px" : "-4px",
                }}
              />
            }
            style={{ color: "white" }}
          >
            <Menu.Item key="12" style={{ color: "white" }}>
              Sales Analytics
            </Menu.Item>
            <Menu.Item key="13" style={{ color: "white" }}>
              Dashboard 2
            </Menu.Item>
            <Menu.Item key="14" style={{ color: "white" }}>
              Dashboard 3
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            key="4"
            icon={<ApiOutlined style={{ fontSize: "17px" }} />}
            style={{ color: "white" }}
          >
            Sources
          </Menu.Item>

          <Menu.Item
            key="5"
            icon={<TeamOutlined style={{ fontSize: "17px" }} />}
            style={{ color: "white" }}
          >
            Engagement
          </Menu.Item>

          <Menu.Item
            key="3"
            icon={<SettingOutlined style={{ fontSize: "17px" }} />}
            style={{ color: "white" }}
          >
            User Management
          </Menu.Item>

          <SubMenu
            title="My Account"
            key="2"
            icon={
              <UserOutlined
                style={{
                  fontSize: "17px",
                  marginLeft: collapsed ? "1px" : "-4px",
                }}
              />
            }
            style={{ color: "white" }}
          >
            <Menu.Item key="21" style={{ color: "white" }}>
              Account Settings
            </Menu.Item>
            <Menu.Item key="22" style={{ color: "white" }}>
              Payment Plan
            </Menu.Item>
            <Menu.Item key="23" style={{ color: "white" }}>
              Notification Preference
            </Menu.Item>
          </SubMenu>

          {/* <Menu.Item
            key="6"
            icon={<CustomerServiceOutlined style={{ fontSize: "17px" }} />}
            style={{ color: "white" }}
          >
            Support
          </Menu.Item> */}
        </Menu>
      </Sider>

      <Layout style={{ marginLeft: collapsed ? "80px" : "200px" }}>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            color: "black",
            position: "fixed",
            width: "100%",
            zIndex: 1,
            display: "flex",
            justifyContent: "space-between", // or 'flex-end'
          }}
        >
          {selectedMenuItem && (
            <div style={{ marginLeft: 16 }} className="headline">
              {selectedMenuItem === "1" && (
                <>
                  <img
                    src={Dashboard}
                    alt="dashboard"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Dashboards
                </>
              )}
              {selectedMenuItem === "2" && (
                <>
                  <img
                    src={user}
                    alt="user"
                    style={{ marginRight: "7px" }}
                  ></img>
                  My Account
                </>
              )}
              {selectedMenuItem === "3" && (
                <>
                  <img
                    src={usergear}
                    alt="user"
                    style={{ marginRight: "7px" }}
                  ></img>
                  User Management
                </>
              )}
              {selectedMenuItem === "4" && (
                <>
                  <img
                    src={plug}
                    alt="user"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Sources
                </>
              )}
              {selectedMenuItem === "5" && (
                <>
                  <img
                    src={users}
                    alt="user"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Engagement
                </>
              )}
              {selectedMenuItem === "6" && (
                <>
                  <img
                    src={headset}
                    alt="user"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Support
                </>
              )}
              {selectedMenuItem === "12" && (
                <>
                  <img
                    src={Dashboard}
                    alt="dashboard"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Dashboards &nbsp;{" "}
                  <p className="dashboards-heading">/ Dashboard1</p>
                </>
              )}
              {selectedMenuItem === "13" && (
                <>
                  <img
                    src={Dashboard}
                    alt="dashboard"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Dashboards &nbsp;{" "}
                  <p className="dashboards-heading">/ Dashboard2</p>
                </>
              )}
              {selectedMenuItem === "14" && (
                <>
                  <img
                    src={Dashboard}
                    alt="dashboard"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Dashboards &nbsp;{" "}
                  <p className="dashboards-heading">/ Dashboard3</p>
                </>
              )}

              {selectedMenuItem === "21" && (
                <>
                  <img
                    src={Dashboard}
                    alt="dashboard"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Dashboards / &nbsp;{" "}
                  <img
                    src={fileuser}
                    alt="dashboard"
                    style={{ marginRight: "7px", height: "20px" }}
                  ></img>{" "}
                  <p className="dashboards-heading"> Account Settings</p>
                </>
              )}
              {selectedMenuItem === "22" && (
                <>
                  <img
                    src={Dashboard}
                    alt="dashboard"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Dashboards / &nbsp;{" "}
                  <img
                    src={creditcardfront}
                    alt="dashboard"
                    className="top-sub-icons"
                    style={{ marginRight: "7px" }}
                  ></img>{" "}
                  <p className="dashboards-heading"> Payment Plan</p>
                </>
              )}
              {selectedMenuItem === "23" && (
                <>
                  <img
                    src={Dashboard}
                    alt="dashboard"
                    style={{ marginRight: "7px" }}
                  ></img>
                  Dashboards / &nbsp;{" "}
                  <img
                    src={bells}
                    alt="dashboard"
                    className="top-sub-icons"
                    style={{ marginRight: "7px" }}
                  ></img>{" "}
                  <p className="dashboards-heading">Notification Preferences</p>
                </>
              )}
            </div>
          )}

          <div style={{ marginLeft: collapsed ? "300px" : "200px" }}>
            <Input
              style={{ width: "550px", borderRadius: "0px" }}
              size="large"
              placeholder="Search for anything"
              prefix={<SearchOutlined style={{ fontSize: "18px" }} />}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginRight: collapsed ? "110px" : "230px",
            }}
          >
            <Avatar
              size={35}
              style={{
                background: "white",
                marginTop: "17px",
                marginLeft: "8px",
                cursor: "pointer",
                color: "grey",
              }}
              icon={<BellFilled style={{ fontSize: "24px" }} />}
            />

            <Avatar
              size={35}
              style={{
                background: "#2EB67D",
                marginTop: "17px",
                marginLeft: "8px",
                cursor: "pointer",
              }}
              icon={<UserOutlined style={{ fontSize: "18px" }} />}
            />
          </div>
        </Header>

        <div style={{ marginTop: "64px", padding: "4px" }}>
          <Content
            style={{
              background: colorBgContainer,
              padding: 10,
              minHeight: "86vh",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "86vh",
                overflowY: "auto",
              }}
            >
              {selectedMenuItem === "12" && (
                <iframe
                  src="https://public.tableau.com/views/DataTramPOC/Overview-Combined?:showVizHome=no&:embed=true"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  allowFullScreen
                  title="Tableau Dashboard"
                />
              )}
              {/* Add conditional rendering for SocialIcons */}
              {selectedMenuItem === "4" && <SocialIcons />}
            </div>
          </Content>
        </div>
      </Layout>
    </Layout>
  );
};

export default App;
