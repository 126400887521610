import { useState } from "react";
import { Modal } from "antd";
import paymentconfirm from "../images/paymentconfirm.png";
import Group from "../images/Group.png";
import cardback from "../images/cardback.png";

interface PaymentModalProps {
  isVisible: boolean;
  onClose: () => void;
  onNext: (isValid: boolean) => void;
  payNowClicked: any;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  isVisible,
  onClose,
  onNext,
  payNowClicked,
}) => {
  const [showOtherContent, setShowOtherContent] = useState(false);
  var PlanSubscribed = false;

  const showOtherModal = () => {
    setShowOtherContent(true);
    PlanSubscribed = true;
    if (PlanSubscribed) {
      onNext(PlanSubscribed);
      payNowClicked();
    }
  };

  return (
    <Modal
      title="Pay Now"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
      width={showOtherContent ? 550 : 800}
      wrapClassName="centered-modal"
    >
      {showOtherContent ? (
        <div
          style={{
            display: "grid",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={paymentconfirm}
              alt="Payment Confirmation"
              className="payment-card"
            />
            <h3 className="chooseplan">Choose Plan</h3>
          </div>
          <h3 className="subscribedplan">
            Your card has been processed! You are now subscribed.
          </h3>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={Group}
              alt="Group"
              className="card"
            />
            <img
              src={cardback}
              alt="Card Back"
              className="card"
            />
          </div>
          <button className="paynow" onClick={showOtherModal}>
            Confirm and Pay
          </button>
        </div>
      )}
    </Modal>
  );
};

export default PaymentModal;
