import React from "react";
import {
  PhoneOutlined,
  MailOutlined,
  HomeOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SolutionOutlined,
  MessageOutlined,
  LineChartOutlined,
  UserOutlined,
  DatabaseOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import DpLogo from "../../images/DpLogo.png";
import { Row, Col, Divider } from "antd";
//import { Color } from "antd/es/color-picker";

export default function PrivacyPage() {
  return (
    <div className="privacy-policy-body">
      <img src={DpLogo} className="privacy-logo" alt="logo" />
      <div>
        <div className="policy-blue-bg ">
          <h1 className="private-policy-title">Privacy Policy</h1>
          <p className="date-prop policy-subheading">
            UPDATED OCTOBER 10 , 2024
          </p>
        </div>

        <p className="mt-4 policy-subheading">
          At Datatram, we value your privacy and are committed to safeguarding
          your personal information. This privacy policy explains how we
          collect, use, disclose, and protect your data when you use our
          services.
        </p>

        <Divider />

        {/* *********************************Info We Collect Section ********************************* */}

        <div>
          <h2 className="mt-4 policy-heading">Information We Collect</h2>
          <p className="text-start policy-description  ">
            When you sign up for Datatram and use our services, we may collect
            the following types of information:
          </p>
          <div className="policy-item  ">
            <div>
              <UserOutlined className="policy-icon mr-3" />
            </div>
            <div className="info-details mt-4">
              <h3 className="policy-subheading">Personal Information</h3>
              <p className="policy-details">
                This includes your name, password, company name, and
                subscription payment details.
              </p>
              <p className="policy-details">
                We collect this information to create and manage your account.
              </p>
            </div>
          </div>
          <div className="policy-item ">
            <div>
              <DatabaseOutlined className="policy-icon mr-3" />
            </div>
            <div className="info-details">
              <h3 className="policy-subheading">
                Data from Integrated Sources
              </h3>
              <p className="policy-details">
                We gather data from the platforms you choose to integrate with,
                such as Shopify, Google Ads, GA4, Facebook, and Instagram.
              </p>
              <p className="policy-details">
                This helps us provide you with comprehensive insights and
                analytics.
              </p>
            </div>
          </div>
        </div>
        <Divider />

        {/* *********************************How we use your info Section ********************************* */}
        <div>
          <h2 className="mt-4 policy-heading">How We Use Your Information</h2>
          <p className="text-start policy-description  ">
            Your information is essential for us to provide and enhance our
            services. We may use your data for the following purposes:
          </p>
          {/* Ant Design Grid Layout */}
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={12} md={6}>
              <div className="use-info-detail policy-card-design">
                <SolutionOutlined className="policy-icon" />
                <h3 className="policy-subheading">Service Provision</h3>
                <p className="policy-details">
                  To create and maintain your account, process payments, and
                  provide you with access to our features.
                </p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <div className="use-info-detail policy-card-design">
                <UserOutlined className="policy-icon" />
                <h3 className="policy-subheading">
                  User Experience Improvement
                </h3>
                <p className="policy-details">
                  To understand how our users interact with Datatram, enabling
                  us to enhance our services and develop new features.
                </p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <div className="use-info-detail policy-card-design">
                <MessageOutlined className="policy-icon" />
                <h3 className="policy-subheading">Communication</h3>
                <p className="policy-details">
                  To send you updates regarding your account and share
                  information about promotions or new features.
                </p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <div className="use-info-detail policy-card-design">
                <LineChartOutlined className="policy-icon" />
                <h3 className="policy-subheading">Analytics</h3>
                <p className="policy-details">
                  To analyze trends and usage patterns, helping us improve our
                  services and provide tailored experiences.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <Divider />

        {/* *********************************Sharing Info Section ********************************* */}
        <div>
          <h2 className="mt-4 policy-heading">Sharing Your Information</h2>
          <p className="text-start policy-description  ">
            Your privacy is important to us, and we do not sell or rent your
            personal information to third parties. However, we may share your
            information in the following circumstances:
          </p>

          <div>
            <SolutionOutlined className="policy-icon" />

            <h3 className="policy-subheading">Service Providers</h3>
            <p className="policy-details">
              We may share your data with trusted partners and service providers
              who assist us in operating our services, conducting our business,
              or servicing you, provided they agree to keep this information
              confidential.
            </p>
          </div>

          <div>
            <EyeOutlined className="policy-icon" />
            <h3 className="policy-subheading">Legal Compliance</h3>
            <p className="policy-details">
              We may disclose your information if required to do so by law or in
              response to valid requests by public authorities (e.g., a court or
              government agency).
            </p>
          </div>
        </div>

        <Divider />

        {/* *********************************Your Rights Section ********************************* */}
        <h2 className="mt-4 policy-heading">Your Rights</h2>
        <p className="text-start policy-description">
          You have the following rights concerning your personal information:
        </p>
        {/* Ant Design Grid Layout */}
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={12} md={8}>
            <div className="rights-detail policy-card-design">
              <EyeOutlined className="policy-icon" />
              <h3 className="policy-subheading">Access</h3>
              <p>
                You can request access to the personal information we hold about
                you.
              </p>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <div className="rights-detail policy-card-design">
              <EditOutlined className="policy-icon" />
              <h3 className="policy-subheading">Correction</h3>
              <p className="policy-details">
                If your information is incorrect or incomplete, you have the
                right to request correction.
              </p>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <div className="rights-detail policy-card-design">
              <DeleteOutlined className="policy-icon" />
              <h3 className="policy-subheading">Deletion</h3>
              <p className="policy-details">
                You may request the deletion of your personal information,
                subject to any legal obligations we may have to retain certain
                data.
              </p>
            </div>
          </Col>
        </Row>
        <div className="information-collection">
          <div className="policy-item ">
            <div>
              <AlertOutlined className="red-icon" />
            </div>
            <p className="text-start policy-description alert ">
              If you wish to exercise any of these rights or have questions
              about your data, please contact us using the information provided
              below.
            </p>
          </div>
          <Divider />
        </div>

        <div>
          <Row gutter={16}>
            {/* ********************************* Data Security Section ********************************* */}
            <Col xs={24} sm={12}>
              <div className="policy-section">
                <h2 className="mt-4 policy-heading text-center">
                  Data Security
                </h2>
                <p className="policy-details text-center">
                  We prioritize the security of your personal information. We
                  employ various security measures, including encryption and
                  access controls, to protect your data from unauthorized
                  access, loss, or misuse. While we strive to use commercially
                  acceptable means to safeguard your information, please
                  remember that no method of transmission over the internet or
                  electronic storage is completely secure.
                </p>
              </div>
              {/* *********************************Changes to This Policy Section ********************************* */}
            </Col>
            <Col xs={24} sm={12}>
              <div className="policy-section">
                <h2 className="mt-4 policy-heading text-center">
                  Changes to This Policy
                </h2>
                <p className="policy-details text-center">
                  We may update this privacy policy periodically to reflect
                  changes in our practices or legal requirements. We will notify
                  you of any significant changes by posting a notice on our
                  website or sending you an email. We encourage you to review
                  this policy regularly to stay informed about our data
                  practices.
                </p>
              </div>
            </Col>
          </Row>
          <Divider />
        </div>

        {/* *********************************Contact Us Section ********************************* */}
        <div>
          <h2 className=" mt-4 policy-heading">Contact Us</h2>
          <p className="text-start policy-description">
            If you have any questions or concerns about this privacy policy or
            our data practices, please contact us at:
          </p>
          {/* Ant Design Grid Layout */}
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={12} md={8}>
              <div className="contact-detail policy-card-design">
                <PhoneOutlined className="policy-icon" />
                <h3 className="policy-subheading">U.S Contact Number</h3>
                <p className="policy-details">+1 (323) 5196545</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="contact-detail policy-card-design">
                <MailOutlined className="policy-icon" />
                <h3 className="policy-subheading">Email</h3>
                <p className="policy-details">info@data-pilot.com</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="contact-detail policy-card-design">
                <HomeOutlined className="policy-icon" />
                <h3 className="policy-subheading">Address</h3>
                <p className="policy-details">
                  Data Pilot USA Inc, 2012 Cambridge Place, South Pasadena, CA
                  91030
                </p>
              </div>
            </Col>
          </Row>

          <p className="text-start policy-subheading mb-4 mt-3 ">
            Thank you for trusting Datatram with your information. We are
            committed to protecting your privacy and providing a secure
            experience.{" "}
          </p>
        </div>

        {/* *********************************Footer Section ********************************* */}

        <div>
          <footer className="policy-blue-bg text-center py-4 mb-1">
            <p className="mb-0 text-white text-center">
              &copy; {new Date().getFullYear()} Datatram. All rights reserved.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}
