import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "antd";
import DTLogo from "../images/dt-logo-white.svg";

const MagicScreen: React.FC = () => {
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showMagicMessage, setShowMagicMessage] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false); // Track fade-out state

  const fetchData = () => {
    return new Promise((resolve) => {
      const loadTime = Math.random() * 7000; // Simulate variable load time (0-7 seconds)
      setTimeout(() => {
        resolve("Data loaded");
      }, loadTime);
    });
  };

  useEffect(() => {
    let progressInterval: NodeJS.Timeout;

    // Start loading data
    fetchData().then(() => {
      setDataLoaded(true);
    });

    // Simulate progress bar updates every 1 seconds
    progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          // increment by 7
          return prev + Math.min(7, 100 - prev);
        }
        //max percentage
        return 100;
      });
    }, 1000);

    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  useEffect(() => {
    // Trigger fade-out of progress bar and text once progress reaches 100%
    if (dataLoaded && progress === 100 && !isFadingOut) {
      setIsFadingOut(true); // Set fade-out flag

      // Wait for fade-out animation to finish before showing magic message
      setTimeout(() => {
        setShowMagicMessage(true); // Show "Now see the magic"
      }, 1500); // Wait for fade-out duration (1.5s)
    }
  }, [dataLoaded, progress, isFadingOut]); // Removed unnecessary dependencies

  useEffect(() => {
    // Navigate after "Now see the magic" message has been shown
    if (showMagicMessage) {
      setTimeout(() => {
        navigate("/WelcomeToDatatram/Analytics");
      }, 2000); // Wait 2 seconds before navigating
    }
  }, [showMagicMessage, navigate]); // Ensures navigate happens only when message is shown

  return (
    <div className="loading-screen">
      <div className="loading-screen-container">
        <div className="dt-logo-loading-scr">
          <img src={DTLogo} alt="Logo" />
        </div>

        <div className="loading-content">
          {showMagicMessage ? (
            <p className="loading-screen-heading now-see-the-magic">
              Now see the magic
            </p>
          ) : (
            <>
              <p
                className={`loading-screen-heading ${
                  isFadingOut ? "fade-out" : ""
                }`}
              >
                DataTram is working its magic
              </p>
              <Progress
                percent={progress}
                status={progress < 100 ? "active" : "success"}
                strokeColor="#3ec3f0" // fill color
                trailColor="white" // background color
                strokeWidth={30}
                //animation
                className={`custom-progress ${isFadingOut ? "fade-out" : ""}`}
                style={{ width: "400px" }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MagicScreen;
