import React, { useState } from "react";
import shopifyIcon from "../../images/shopify-logo.png";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnected: () => void;
}

const ShopifyModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConnected,
}) => {
  const [accessToken, setAccessToken] = useState<string>("");
  const [storeName, setStoreName] = useState("");
  const [authentication, setAuthentication] = useState<string>(
    "Admin API Access Token"
  );
  const [errorMessage, setErrorMessage] = useState<string>(""); // State for error message

  const shopifyConnected = () => {
    onConnected();
  };

  if (!isOpen) return null;

  const dummyResponse = {
    accesstoken: "48433abe81a69c0d723959d78c5233bb",
    password: "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",
    version: "2022-07",
  };

  async function testConnection(): Promise<void> {
    setErrorMessage(""); // Reset error message before testing connection
    const storeURLElement = document.getElementById(
      "store"
    ) as HTMLInputElement | null;

    const storeURL = storeURLElement?.value || "";

    if (!storeURL) {
      setErrorMessage("Please enter the store URL.");
      return;
    }

    const data = {
      password: dummyResponse.password,
      version: dummyResponse.version,
      accessToken: dummyResponse.accesstoken,
      storeURL,
      source: "shopify",
    };

    try {
      const response = await fetch(
        "https://dev.datatram.ai/api/shopify/test-connection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert("Response: " + JSON.stringify(result));
      } else {
        setErrorMessage("Failed to connect. Please try again.");
      }
    } catch (error: any) {
      setErrorMessage("Error: " + error.message);
    }
  }

  async function createConnection(): Promise<void> {
    setErrorMessage(""); // Reset error message before creating connection
    const storeURLElement = document.getElementById(
      "store"
    ) as HTMLInputElement | null;

    const storeURL = storeURLElement?.value || "";

    if (!storeURL) {
      setErrorMessage("Please enter the store URL.");
      return;
    }

    const data = {
      password: dummyResponse.password,
      version: dummyResponse.version,
      accessToken,
      storeURL,
      source: "shopify",
    };

    try {
      const response = await fetch(
        "https://dev.datatram.ai/api/shopify/create-connection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert("Response: " + JSON.stringify(result));
        shopifyConnected();
      } else {
        setErrorMessage("Failed to connect. Please try again.");
      }
    } catch (error: any) {
      setErrorMessage("Error: " + error.message);
    }
  }

  const handleContinueWithShopify = () => {
    setAccessToken(dummyResponse.accesstoken); // Populate the access token field with a dummy token
    window.open(
      "https://www.shopify.com/store-login",
      "_blank",
      "width=500,height=400"
    );
  };

  return (
    <div className="source-modal">
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
            type="button"
            className="btn-close close-btn"
            onClick={onClose}
          ></button>
          <div className="innerbody">
            {errorMessage && ( // Render error message if it exists
              <div className="error-message-source-connector ">
                <span className="error-icon-source-connector">⚠️</span>
                <span>{errorMessage}</span>
              </div>
            )}
            <img id="shopify-logo" src={shopifyIcon} alt="Shopify Icon" />
            <div className="shopify-signin">
              <div className="form-selection">
                <label htmlFor="store" className="storeOne">
                  Store
                </label>
                <input
                  type="text"
                  id="store"
                  className="form-control"
                  placeholder="Enter store URL"
                  value={storeName}
                  required
                  onChange={(e) => setStoreName(e.target.value)}
                />
              </div>
              <button
                id="login-via-shopify"
                type="button"
                onClick={handleContinueWithShopify} // Updated to use the new function
                className="shopify-signin-btn btn btn-secondary"
              >
                Continue with Shopify
              </button>
            </div>

            <div className="form-input">
              <label htmlFor="authentication">Authentication</label>
              <select
                id="authentication"
                className="form-select"
                value={authentication}
                onChange={(e) => setAuthentication(e.target.value)}
              >
                <option value="Admin API Access Token">
                  Admin API Access Token
                </option>
                <option value="OAuth 2.0">OAuth 2.0</option>
              </select>

              <div className="form-input mt-3">
                <label htmlFor="accessToken">Access Token</label>
                <input
                  type="password"
                  id="accessToken"
                  className="form-control"
                  placeholder="Enter your access token"
                  value={accessToken}
                  readOnly
                  onChange={(e) => setAccessToken(e.target.value)}
                />
              </div>
            </div>

            <div className="button-group">
              <button className="create-connection" onClick={createConnection}>
                Create Connection
              </button>
              <button className="cancel" onClick={onClose}>
                Cancel
              </button>
              <button className="test-connection" onClick={testConnection}>
                Test Connection
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopifyModal;
