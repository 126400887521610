// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/DatatramSignup.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex;
  padding-left: 10px;
  height: 100vh;
}

.body {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

* {
  margin: 0;
  padding: 0;
}

.formdesign {
  background-color: white;
  padding: 30px 75px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  max-height: 83vh;
  margin-left: 92px;
  max-width: 480px;
  margin-top: -17px;
}

@media screen and (max-width: 900px) {
  .formdesign {
    padding: 30px 4%;
    max-height: 65vh;
  }
}

h1 {
  color: rgb(77, 1, 77);
  text-align: center;
}

h2 {
  color: rgb(107, 42, 119);
  padding-top: 10px;
  font-size: 25px;
}

a {
  color: #2596be;
}

.or {
  color: #bac7d5 !important;
  font-size: 12px !important;
  margin-bottom: -10px !important;
  margin-top: -3px !important;
}

button {
  width: 100%;
  height: 50px;
  padding: 10px;
  border: none;
  background-color: #2596be;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yDAAoD;EACpD,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;;AAE7B;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,uCAAuC;EACvC,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;IAChB,gBAAgB;EAClB;AACF;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,+BAA+B;EAC/B,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".app {\n  display: flex;\n  padding-left: 10px;\n  height: 100vh;\n}\n\n.body {\n  background-image: url(\"./images/DatatramSignup.png\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n\n.formdesign {\n  background-color: white;\n  padding: 30px 75px;\n  border-radius: 5px;\n  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);\n  max-height: 83vh;\n  margin-left: 92px;\n  max-width: 480px;\n  margin-top: -17px;\n}\n\n@media screen and (max-width: 900px) {\n  .formdesign {\n    padding: 30px 4%;\n    max-height: 65vh;\n  }\n}\n\nh1 {\n  color: rgb(77, 1, 77);\n  text-align: center;\n}\n\nh2 {\n  color: rgb(107, 42, 119);\n  padding-top: 10px;\n  font-size: 25px;\n}\n\na {\n  color: #2596be;\n}\n\n.or {\n  color: #bac7d5 !important;\n  font-size: 12px !important;\n  margin-bottom: -10px !important;\n  margin-top: -3px !important;\n}\n\nbutton {\n  width: 100%;\n  height: 50px;\n  padding: 10px;\n  border: none;\n  background-color: #2596be;\n  color: white;\n  border-radius: 5px;\n  font-weight: bold;\n  font-size: 18px;\n  cursor: pointer;\n  margin-top: 15px;\n  margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
