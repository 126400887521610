import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DpLogo from '../../images/DpLogo.png';
import './Confirmresetpassword'
import envelopeopentext from '../../images/envelopeopentext.png'
export default function Resetpassword() {
  const navigate = useNavigate();


 



  return (
    <>
   <div className="bg">
     <img src={DpLogo} className="imgdesign" alt="img" />
      <br />
      <br />
      <div className="app">
        <form className="form-design-otp"
        //  onSubmit={handleSubmit}
         >
           <img src={envelopeopentext} className="user-lock" alt="envelopeopentext" />
           <h2 className='Reset-password'>Follow The Link</h2>
           <p className='Reset-detail'>A password reset link has been sent to your email address. Follow the link to reset your password .</p>
<div >

</div>
          
<p style={{paddingTop:"80px"}}>
Didn’t receive the email?&nbsp;
            <a href>Resend</a>
          </p>

          <p style={{marginTop:"10px"}}>
          Remember your password?&nbsp;
            <a href>Sign In</a>
          </p>
    

       

          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
    </>
  );
}
