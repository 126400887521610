import React, { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import FBlogo from "../../images/logo-fb.svg";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnected: () => void;
}

interface Option {
  label: string;
  value: string;
}

const FacebookModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConnected,
}) => {
  const [accessToken, setAccessToken] = useState<string>(
    "This field is read-only"
  );
  const [selectedAccountIds, setSelectedAccountIds] = useState<Option[]>([]);
  const [adAccountOptions, setAdAccountOptions] = useState<Option[]>([]);
  const [adAccounts, setAdAccounts] = useState<string[]>([]);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState<string[]>([]);

  const FACEBOOK_APP_ID =
    process.env.REACT_APP_FACEBOOK_APP_ID || "552827217194417";
  const FACEBOOK_API_VERSION =
    process.env.REACT_APP_FACEBOOK_API_VERSION || "v21.0";

  useEffect(() => {
    // Load Facebook SDK
    (function (d, s, id) {
      const js = d.createElement(s) as HTMLScriptElement;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs?.parentNode?.insertBefore(js, fjs);

      js.onload = () => {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: FACEBOOK_API_VERSION,
          });

          // Automatically check login status once SDK is initialized
          checkLoginStatus();
        };
      };
    })(document, "script", "facebook-jssdk");
  }, [FACEBOOK_APP_ID, FACEBOOK_API_VERSION]);

  // Check Facebook Login Status
  const checkLoginStatus = () => {
    window.FB.getLoginStatus((response: any) => {
      if (response.status === "connected") {
        handleLogin(response);
      } else {
        login();
      }
    });
  };

  const FBConnected = () => {
    onConnected();
  };

  async function testConnection(): Promise<void> {
    const accessToken = (
      document.getElementById("accessToken") as HTMLInputElement
    )?.value;
    const storeURL = (document.getElementById("store") as HTMLInputElement)
      ?.value;

    console.log("Access Token from Input:", accessToken);

    if (!accessToken) {
      alert("Please enter the access token.");
      return;
    }

    if (!storeURL) {
      alert("Please enter the store URL.");
      return;
    }

    try {
      const response = await fetch("/shopify/test-connection", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ accessToken, storeURL }), // Send access token and store URL as JSON
      });

      if (response.ok) {
        const result = await response.json();
        alert("Response: " + JSON.stringify(result));
      } else {
        alert("Failed to connect. Status: " + response.status);
      }
    } catch (error: unknown) {
      console.error("Error connecting:", error);
      alert(
        "Error: " + (error instanceof Error ? error.message : "Unknown error")
      );
    }
  }

  async function createConnection(): Promise<void> {
    const accessToken = (
      document.getElementById("accessToken") as HTMLInputElement
    )?.value; // Get the access token from the input field
    console.log("Access Token from Input:", accessToken);

    if (!accessToken) {
      alert("Please enter the access token.");
      return;
    }

    try {
      const response = await fetch(
        "http://localhost:8000/shopify/create-connection",
        {
          method: "POST", // Use POST to send data
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ accessToken }), // Send the access token as JSON
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert("Response: " + JSON.stringify(result));
        FBConnected();
      } else {
        alert("Failed to connect. Status: " + response.status);
      }
    } catch (error: unknown) {
      console.error("Error connecting:", error);
      alert(
        "Error: " + (error instanceof Error ? error.message : "Unknown error")
      );
    }
  }

  // Handle Login
  const handleLogin = (response: any) => {
    if (response.authResponse) {
      const token = response.authResponse.accessToken;
      setAccessToken(token);

      // Fetch user details
      window.FB.api("/me", { fields: "name,email" }, async (userInfo: any) => {
        console.log("User Info:", userInfo);
        await saveTokenToServer(token, userInfo);
        fetchAdAccounts(token);
      });
    } else {
      console.log("User cancelled login or did not fully authorize.");
    }
  };

  // Facebook Login Function
  const login = () => {
    window.FB.login(
      (response: any) => {
        if (response.authResponse) {
          handleLogin(response);
        }
      },
      {
        scope:
          "email, public_profile, ads_read, ads_management, business_management, pages_show_list, pages_read_engagement, instagram_basic, instagram_manage_insights, instagram_content_publish, pages_messaging, page_events, leads_retrieval, pages_manage_engagement, pages_manage_posts, pages_read_user_content, read_insights",
      }
    );
  };

  // Save Token to Server
  const saveTokenToServer = async (token: string, userInfo: any) => {
    try {
      const response = await fetch("http://localhost:8000/receive-token", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `access_token=${token}&name=${encodeURIComponent(
          userInfo.name
        )}&email=${encodeURIComponent(userInfo.email)}`,
      });
      const data = await response.json();
      if (data.long_lived_token) {
        setAccessToken(data.long_lived_token);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  // Fetch Ad Accounts
  const fetchAdAccounts = async (token: string) => {
    try {
      const response = await fetch(
        `http://localhost:8000/ad-accounts?access_token=${token}`
      );
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          alert(
            "Your session has expired or your password was changed. Please sign in again."
          );
          login();
          return;
        }
        throw new Error("Failed to fetch ad accounts");
      }
      const data = await response.json();
      if (data.ad_account_ids) {
        setAdAccounts(data.ad_account_ids);
      }
    } catch (error) {
      console.error("Error fetching ad accounts:", error);
    }
  };

  // // Test Connection
  // const testConnection = async () => {
  //   if (!accessToken) {
  //     alert("Access Token is missing. Please log in with Facebook.");
  //     return;
  //   }

  //   if (selectedAdAccounts.length === 0) {
  //     alert("Please select at least one Ad Account ID.");
  //     return;
  //   }

  //   try {
  //     const response = await fetch("/test-connection", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         accessToken,
  //         adAccountId: selectedAdAccounts,
  //       }),
  //     });

  //     const results = await response.json();
  //     results.forEach((result: any) => {
  //       if (result.success) {
  //         alert(
  //           `Connection successful for Ad Account ${result.ad_account_id}: ${result.message}`
  //         );
  //       } else {
  //         alert(
  //           `Connection failed for Ad Account ${result.ad_account_id}: ${result.error}`
  //         );
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error testing connection:", error);
  //     alert("An error occurred while testing the connection.");
  //   }
  // };

  if (!isOpen) return null;

  return (
    <div className="source-modal">
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
            type="button"
            className="btn-close close-btn"
            onClick={onClose}
          ></button>
          <div className="innerbody">
            <img className="logo-fb" src={FBlogo} alt="Facebook Icon" />

            <div className="shopify-signin">
              <div className="form-selection access-tkn-fb">
                <label htmlFor="accessToken">Access Token</label>
                <input
                  type="password"
                  id="accessToken"
                  className="form-control"
                  value={accessToken}
                  readOnly
                />
              </div>

              <button
                type="button"
                onClick={login}
                className="facebook-signin-btn btn btn-secondary"
              >
                Continue with Facebook
              </button>
            </div>

            <div className="form-input">
              <label htmlFor="grantedScopes">Select Account Id(s)</label>
              <Select
                id="account-ids"
                isMulti
                options={adAccountOptions}
                value={selectedAccountIds}
                // onChange={handleAccountIdsChange}
                placeholder="Select Account Ids"
              />
            </div>

            <div className="button-group">
              <button className="create-connection" onClick={createConnection}>
                Create Connection
              </button>
              <button className="cancel" onClick={onClose}>
                Cancel
              </button>
              <button className="test-connection" onClick={testConnection}>
                Test Connection
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookModal;
