import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserIntro from "../UserIntro";
import PaymentConfirmation from "../PaymentConfirmation";
import SocialIcons from "../SourceConnector/Source-Connector-Screen";
import PlansOverview from "../PlansOverview";
import tickIcon from "../../images/tick.svg";
import MagicScreen from "../MagicScreen";

const steps = [
  {
    id: 1,
    heading: "Hello! Welcome to Datatram",
    name: "Basic Information",
    component: UserIntro,
  },
  {
    id: 2,
    heading:
      "We offer something for everyone. Choose the plan that speaks your business",
    name: "Choose Plan",
    component: PlansOverview,
  },
  {
    id: 3,
    heading: "Almost there. Connect to as many data sources as possible.",
    name: "Connect Data Sources",
    component: SocialIcons,
  },
];

export default function Sidebar() {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(
    Array(steps.length).fill(false)
  );
  const [payNowClicked, setPayNowClicked] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [showMagicScreen, setShowMagicScreen] = useState(false);

  // **New State to Track Connected Connectors**
  const [connectedConnectors, setConnectedConnectors] = useState<string[]>([]);

  const confirmPayment = () => {
    setPayNowClicked(true);
  };

  const nextStep = (isValid: boolean) => {
    if (steps[currentStep].id === 2 && !isPaymentStep) {
      // Transition to Payment Confirmation sub-step
      setIsPaymentStep(true);
    } else if (currentStep < steps.length - 1 && isValid) {
      // Move to the next step if valid
      setCompletedSteps((prev) => {
        const updatedSteps = [...prev];
        updatedSteps[currentStep] = true;
        return updatedSteps;
      });
      setIsPaymentStep(false); // Reset payment step flag
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    if (isPaymentStep) {
      // If on Payment Confirmation, go back to Plans Overview
      setIsPaymentStep(false);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleDoneClick = () => {
    setShowMagicScreen(true);
  };

  const navigate = useNavigate();
  if (showMagicScreen) {
    navigate("/WelcomeToDatatram/loading");
  }

  const CurrentComponent = isPaymentStep
    ? PaymentConfirmation
    : steps[currentStep]?.component;

  const handlePaymentConfirmation = (confirmed: boolean) => {
    setIsPaymentConfirmed(confirmed);
    if (confirmed) {
      setIsPaymentStep(false); // Reset payment step flag
      setCurrentStep((prev) => prev + 1); // Move to the next step after payment confirmation
    }
  };

  // **Callback to Handle New Connections**
  const handleConnectionCreated = (connectorName: string) => {
    setConnectedConnectors((prev) => [...prev, connectorName]);
  };

  // **Determine if Current Step is SourceConnector (step 3)**
  const isSourceConnectorStep = steps[currentStep].id === 3;

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={8} sm={6} md={6} lg={4} xl={4} xxl={4} className="stepbody">
          <div className="sidebar-heading-container">
            <p className="stepheading">
              {isPaymentStep
                ? "Confirm Your Payment"
                : steps[currentStep].heading}
            </p>
          </div>
          <div className="steps-list-section">
            <ul>
              {steps.map((step, index) => (
                <li key={index} className="step-item">
                  <div className="step-icon">
                    {index < currentStep || (index === 1 && isPaymentStep) ? (
                      <img src={tickIcon} alt="Completed" className="tick-icon" />
                    ) : (
                      index + 1
                    )}
                  </div>
                  <span className="step-name">{step.name}</span>
                </li>
              ))}
            </ul>
          </div>

          <div
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {currentStep > 0 && (
              <Button className="prebutton" onClick={prevStep}>
                <span className="button-icon">
                  <ArrowLeftOutlined />
                </span>
              </Button>
            )}

            <Button
              className="nextbutton"
              onClick={() => {
                if (steps[currentStep].id === 1) {
                  (document.getElementById("nextBtn") as HTMLElement).click();
                } else if (steps[currentStep].id === steps.length) {
                  handleDoneClick();
                } else {
                  nextStep(true);
                }
              }}
              disabled={
                (steps[currentStep].id === 2 &&
                  !payNowClicked &&
                  !isPaymentStep) ||
                (steps[currentStep].id === 3 && connectedConnectors.length === 0) // **Updated Condition**
              }
            >
              {currentStep === steps.length - 1 ? "Done" : "Next"}
            </Button>
          </div>
        </Col>

        <Col xs={16} sm={18} md={18} lg={18} xl={18} xxl={18} >
          {isSourceConnectorStep ? (
            <SocialIcons
              onNext={(isValid: boolean) => nextStep(isValid)}
              onBack={
                steps[currentStep].component === PlansOverview || isPaymentStep
                  ? prevStep
                  : () => { }
              }
              payNowClicked={confirmPayment}
              onConnectionCreated={handleConnectionCreated} // **Only for SocialIcons**
              {...(CurrentComponent === PaymentConfirmation
                ? { handlePaymentConfirmation }
                : {})}
            />
          ) : (
            <CurrentComponent
              onNext={(isValid: boolean) => nextStep(isValid)}
              onBack={
                steps[currentStep].component === PlansOverview || isPaymentStep
                  ? prevStep
                  : () => { }
              }
              payNowClicked={confirmPayment}
              {...(CurrentComponent === PaymentConfirmation
                ? { handlePaymentConfirmation }
                : {})}
            />
          )}
        </Col>
      </Row>
    </Fragment>
  );
}
