import React, { useState, useEffect } from "react";
import ShopifyModal from "./Shopify-Modal";
import FacebookModal from "./Facebook-Modal";
import GoogleAdsModal from "./Google-Ads-Modal";
import GoogleAnalyticsModal from "./Google-Analytics-Modal";
import InstagramModal from "./Instagram-Modal";
import MetaModal from "./Meta-Modal";

interface SocialIconsProps {
  onConnectionCreated?: (connectorName: string) => void; // **Accept Connector Name**
  onNext: (isValid: boolean) => void;
  onBack: () => void;
  payNowClicked: () => void;
}

const SocialIcons: React.FC<SocialIconsProps> = ({ onConnectionCreated }) => {


  const [isShopifyModalOpen, setShopifyModalOpen] = useState<boolean>(false);
  const [isFacebookModalOpen, setFacebookModalOpen] = useState<boolean>(false);
  const [isGoogleAdsModalOpen, setGoogleAdsModalOpen] =
    useState<boolean>(false);
  const [isGoogleAnalyticsModalOpen, setGoogleAnalyticsModalOpen] =
    useState<boolean>(false);
  const [isInstagramModalOpen, setInstagramModalOpen] =
    useState<boolean>(false);
  const [isMetaModalOpen, setMetaModalOpen] = useState<boolean>(false);

  const [connectedConnectors, setConnectedConnectors] = useState<string[]>([]);
  const isNextButtonEnabled = connectedConnectors.length > 0;

  const socialMediaImages: Record<string, string> = {
    Shopify: require("./Images/shopify.png"),
    GoogleAds: require("./Images/google.png"),
    GoogleAnalytics: require("./Images/googleanalytics.png"),
    Meta: require("./Images/meta.png"),
    Facebook: require("./Images/facebook.png"),
    Instagram: require("./Images/instagram.png"),
    Snapchat: require("./Images/snapchat.png"),
    Finish: require("./Images/finish.png"),
  };

  const socialMediaOrder: string[] = [
    "Shopify",
    "GoogleAds",
    "GoogleAnalytics",
    "Meta",
    "Facebook",
    "Instagram",
    "Snapchat",
    "Finish",
  ];

  const handleConnectorConnected = (connectorName: string) => {
    if (!connectedConnectors.includes(connectorName)) {
      setConnectedConnectors((prev) => [...prev, connectorName]);
      if (onConnectionCreated) {
        onConnectionCreated(connectorName); // **Pass Connector Name**
      }
    }
  };

  const handleNextButtonClick = () => {
    console.log("Navigating to templates screen...");
    // Optionally, you can trigger onNext here if needed
  };

  return (
    <>
      <div>
        <p className="mainheading">Connect Data Sources</p>
        <p className="finishline">
          Get to the finish line with as many green connections to ensure data
          accuracy
        </p>
      </div>
      <div className="icon-grid zigzag-icons-container">
        {socialMediaOrder.map((socialMedia, index) => (
          <div
            key={index}
            className={`icon frequency-icon ${socialMedia.toLowerCase()}-icon ${connectedConnectors.includes(socialMedia) ? "connected" : ""
              }`}
            style={{
              marginLeft: index % 2 === 0 ? "20px" : "100px",
            }}
          >
            <img
              src={socialMediaImages[socialMedia]}
              alt={socialMedia}
              onClick={() => {
                if (socialMedia === "Shopify") {
                  setShopifyModalOpen(true);
                } else if (socialMedia === "Facebook") {
                  setFacebookModalOpen(true);
                } else if (socialMedia === "GoogleAds") {
                  setGoogleAdsModalOpen(true);
                } else if (socialMedia === "GoogleAnalytics") {
                  setGoogleAnalyticsModalOpen(true);
                } else if (socialMedia === "Instagram") {
                  setInstagramModalOpen(true);
                } else if (socialMedia === "Meta") {
                  setMetaModalOpen(true);
                }
              }}
              className={`social-media-image ellipse ${socialMedia}`}
            />
          </div>
        ))}
      </div>

      {/* Modals */}
      {isShopifyModalOpen && (
        <ShopifyModal
          isOpen={isShopifyModalOpen}
          onClose={() => setShopifyModalOpen(false)}
          onConnected={() => {
            handleConnectorConnected("Shopify");
            setShopifyModalOpen(false);
          }}
        />
      )}

      {isFacebookModalOpen && (
        <FacebookModal
          isOpen={isFacebookModalOpen}
          onClose={() => setFacebookModalOpen(false)}
          onConnected={() => {
            handleConnectorConnected("Facebook");
            setFacebookModalOpen(false);
          }}
        />
      )}

      {isGoogleAdsModalOpen && (
        <GoogleAdsModal
          isOpen={isGoogleAdsModalOpen}
          onClose={() => setGoogleAdsModalOpen(false)}
          onConnected={() => {
            handleConnectorConnected("GoogleAds");
            setGoogleAdsModalOpen(false);
          }}
        />
      )}

      {isGoogleAnalyticsModalOpen && (
        <GoogleAnalyticsModal
          isOpen={isGoogleAnalyticsModalOpen}
          onClose={() => setGoogleAnalyticsModalOpen(false)}
          onConnected={() => {
            handleConnectorConnected("GoogleAnalytics");
            setGoogleAnalyticsModalOpen(false);
          }}
        />
      )}

      {isInstagramModalOpen && (
        <InstagramModal
          isOpen={isInstagramModalOpen}
          onClose={() => setInstagramModalOpen(false)}
          onConnected={() => {
            handleConnectorConnected("Instagram");
            setInstagramModalOpen(false);
          }}
        />
      )}

      {isMetaModalOpen && (
        <MetaModal
          isOpen={isMetaModalOpen}
          onClose={() => setMetaModalOpen(false)}
          onConnected={() => {
            handleConnectorConnected("Meta");
            setMetaModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default SocialIcons;
