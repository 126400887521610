// src/components/GoogleAnalyticsModal.tsx
import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import googleAnalyticsIcon from "./Images/googleanalytics.png"; // Adjust the path as needed

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnected: () => void;
}

// interface DecodedDetails {
//   [key: string]: any;
// }

const GoogleAnalyticsModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConnected,
}) => {
  const [accessToken, setAccessToken] = useState<string>("");
  const [accountsFetched, setAccountsFetched] = useState<boolean>(false);
  const [managerAccountId, setManagerAccountId] = useState<string>("");
  const [customers, setCustomers] = useState<
    Array<{ id: string; name: string }>
  >([]);

  // Define the scopes for Google Analytics
  const googleAnalyticsScopes = [
    "https://www.googleapis.com/auth/analytics.readonly",
    // Add more scopes as needed
  ];

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     console.log("Google Analytics Token Response:", tokenResponse);

  //     try {
  //       if ('access_token' in tokenResponse) {
  //         const decoded: DecodedDetails = jwt_decode(tokenResponse.access_token);
  //         console.log("Decoded Token:", decoded);
  //         setAccessToken(tokenResponse.access_token);
  //       }
  //     } catch (error) {
  //       console.error("Error decoding token:", error);
  //     }

  //     // Close the modal after successful login
  //     onClose();
  //   },
  //   onError: (errorResponse) => {
  //     console.error("Login Failed:", errorResponse);
  //   },
  //   scope: googleAnalyticsScopes.join(" "),
  //   prompt: "consent", // Force consent screen
  //   flow: "implicit", // Specify the flow type explicitly
  // });

  const signInWithGoogle = () => {
    setCustomers([]);
    setAccountsFetched(false);

    const CLIENT_ID =
      "996633909190-2nnsu9ssi3kmjtkct4dsvt9e7hdgn1fj.apps.googleusercontent.com";
    const REDIRECT_URI = "http://localhost:8000/gads/oauth/callback";

    const oauthUrl =
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${CLIENT_ID}` +
      `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}` +
      `&response_type=code` +
      `&scope=https://www.googleapis.com/auth/analytics.readonly` +
      `&access_type=offline`;

    window.open(oauthUrl, "oauthWindow", "width=600,height=600");
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { refresh_token } = event.data;
      if (refresh_token) {
        setAccessToken(refresh_token);
        fetchCustomers(refresh_token);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const fetchCustomers = (token: string) => {
    const accountSelect = document.getElementById(
      "account"
    ) as HTMLSelectElement;
    accountSelect.innerHTML = '<option value="">Select Customer</option>';

    fetch(`/gads/customers?refresh_token=${token}`)
      .then((response) => response.json())
      .then((data) => {
        setCustomers(data.customer_accounts);
        setManagerAccountId(data.manager_account_id);
        setAccountsFetched(true);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  };

  const testConnection = () => {
    if (!accessToken) {
      alert("No token available. Please sign in first.");
      return;
    }

    const accountSelect = document.getElementById(
      "account"
    ) as HTMLSelectElement;
    const customerId = accountSelect.value;

    if (!customerId) {
      alert("Please select a customer.");
      return;
    }

    fetch(
      `/gads/test_connection?refresh_token=${accessToken}&customer_id=${customerId}`,
      {
        headers: {
          "login-customer-id": managerAccountId,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Connection test successful!");
        } else {
          alert("Connection test failed: " + data.error);
        }
      })
      .catch((error) => {
        console.error("Error testing connection:", error);
        alert("Failed to test connection: " + error.message);
      });
  };

  const createConnection = () => {
    if (!accessToken) {
      alert("No refresh token available. Please sign in first.");
      return;
    }

    const payload = { refresh_token: accessToken };

    fetch(`/gads/create_connection`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Connection created successfully!");
      })
      .catch((error) => {
        console.error("Error creating connection:", error);
        alert("Failed to create connection: " + error.message);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="source-modal">
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
            type="button"
            className="btn-close close-btn"
            onClick={onClose}
          ></button>
          <div className="innerbody">
            <img
              id="Google-Analytics-Icon"
              src={googleAnalyticsIcon}
              alt="Google-Analytics-Icon"
            />

            <div className="shopify-signin">
              <button
                type="button"
                onClick={() => signInWithGoogle()}
                className="google-signin-btn btn btn-primary"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  border: "none",
                  borderRadius: "4px",
                  backgroundColor: "#4285F4",
                  color: "white",
                  cursor: "pointer",
                  marginBottom: "15px",
                }}
              >
                <img
                  src="https://developers.google.com/identity/images/g-logo.png"
                  alt="Google logo"
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />
                Sign in with Google Analytics
              </button>
            </div>

            <div className="form-input">
              <div className="form-input mt-3">
                <label htmlFor="accessToken">Token</label>
                <input
                  type="text"
                  id="accessToken"
                  className="form-control"
                  readOnly
                  value={accessToken}
                  disabled
                />
              </div>

              <div className="form-input mt-3">
                <label htmlFor="customer">Account</label>
                <input
                  type="text"
                  id="customer"
                  className="form-control"
                  // value={customer}
                  // onChange={(e) => setCustomer(e.target.value)}
                />
              </div>

              <div className="form-input mt-3">
                <label htmlFor="property">Property</label>
                <input
                  type="text"
                  id="property"
                  className="form-control"
                  // value={property}
                  // onChange={(e) => setProperty(e.target.value)}
                />
              </div>

              <div className="form-input mt-3">
                <label htmlFor="authentication">Metadata</label>
                {/* Bootstrap dropdown for authentication methods */}
                <select
                  id="authentication"
                  className="form-select"
                  // value={authentication}
                  // onChange={(e) => setAuthentication(e.target.value)}
                >
                  <option value="Admin API Access Token">Enabled</option>
                  <option value="OAuth 2.0">Disabled</option>
                </select>
              </div>
            </div>

            <div className="button-group">
              <button className="create-connection">Create Connection</button>
              <button className="cancel" onClick={onClose}>
                Cancel
              </button>
              <button className="test-connection">Test Connection</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//   return (
//     <div className="source-modal">
//       <div className="modal-overlay" onClick={onClose}>
//         <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//         <button
//             type="button"
//             className="btn-close close-btn"
//             onClick={onClose}
//           ></button>

//           <div className="shopify-login">
//             <img
//               id="Google-Analytics-Icon"
//               src={googleAnalyticsIcon}
//               alt="Google-Analytics-Icon"
//             />

//             <div className="shopify-signin">
//               <button
//                 type="button"
//                 onClick={() => signInWithGoogle()}
//                 className="google-signin-btn btn btn-primary"
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "4px",
//                   backgroundColor: "#4285F4",
//                   color: "white",
//                   cursor: "pointer",
//                   marginBottom: "15px",
//                 }}
//               >
//                 <img
//                   src="https://developers.google.com/identity/images/g-logo.png"
//                   alt="Google logo"
//                   style={{ width: "20px", height: "20px", marginRight: "10px" }}
//                 />
//                 Sign in with Google Analytics
//               </button>
//             </div>

//             <div className="form-input">
//               <div className="form-selection">
//                 <label htmlFor="accessToken">Token</label>
//                 <input
//                   type="text"
//                   id="accessToken"
//                   className="form-control"
//                   readOnly
//                   value={accessToken}
//                   disabled
//                 />
//               </div>

//               <div className="form-input mt-3">
//                 <label htmlFor="customer">Account</label>
//                 <input
//                   type="text"
//                   id="customer"
//                   className="form-control"
//                   // value={customer}
//                   // onChange={(e) => setCustomer(e.target.value)}
//                 />
//               </div>

//               <div className="form-input mt-3">
//                 <label htmlFor="property">Property</label>
//                 <input
//                   type="text"
//                   id="property"
//                   className="form-control"
//                   // value={property}
//                   // onChange={(e) => setProperty(e.target.value)}
//                 />
//               </div>

//               <div className="form-input mt-3">
//                 <label htmlFor="authentication">Metadata</label>
//                 {/* Bootstrap dropdown for authentication methods */}
//                 <select
//                   id="authentication"
//                   className="form-select"
//                   // value={authentication}
//                   // onChange={(e) => setAuthentication(e.target.value)}
//                 >
//                   <option value="Admin API Access Token">Enabled</option>
//                   <option value="OAuth 2.0">Disabled</option>
//                 </select>
//               </div>
//             </div>

//             <div className="button-group">
//               <button className="create-connection">
//                 Create Connection
//               </button>
//               <button className="cancel" onClick={onClose}>
//                 Cancel
//               </button>
//               <button className="test-connection">
//                 Test Connection
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default GoogleAnalyticsModal;
