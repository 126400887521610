import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../FormInput";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { Divider } from "antd";
import DpLogo from "../../images/DpLogo.png";
import GoogleLogo from "../../images/google-logo.svg";

interface InputType {
  id: number;
  name: string;
  type: string;
  errorMessage: string;
  label: string;
  required: boolean;
  pattern?: string;
}

const SignUpContent = () => {

  const navigate = useNavigate();
  const [values, setValues] = useState({
    username: "",
    email: "",
    birthday: "",
    password: "",
    confirmPassword: "",
  });
  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);

  const inputs: InputType[] = [
    {
      id: 1,
      name: "email",
      type: "email",
      errorMessage: "Incorrect format for email address",
      label: "Email Address",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      errorMessage: "Password is weak",
      label: "Create Password",
      required: true,
    },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isGoogleSignUp) {
      navigate("/Otp");
    }
  };

  const handleCreateAccount = () => {
    // Add logic for account creation
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleNavigate = () => {
    window.location.href = "/WelcomeToDatatram";
  };

  const googleScopes = [
    "https://www.googleapis.com/auth/adwords",
    "https://www.googleapis.com/auth/analytics.readonly",
  ];

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      setIsGoogleSignUp(true); // Set flag to true for Google sign-up
      handleNavigate();
    },
    scope: googleScopes.join(" "),
  });

  return (
    <div className="body">
      <img src={DpLogo} className="logo-design" alt="logo" />

      <div className="app">
        <div className="signup-internal-container">
          <form className="formdesign" onSubmit={handleSubmit}>
            <h2 className="signup-heading">
              Meaningful insights to grow <br /> your business
            </h2>
            <br />

            <button
              onClick={() => {
                setIsGoogleSignUp(true);
                login();
              }}
              className="custom-google-button"
              style={{
                width: "335px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ccc",
                borderRadius: "4px",
                background: "white",
                cursor: "pointer",
                color: "black",
              }}
              type="button"
            >
              <img
                src={GoogleLogo}
                alt="Google logo"
                style={{
                  marginRight: "10px",
                  width: "18px",
                  height: "18px",
                }}
              />
              Sign up with Google
            </button>

            <br />
            <Divider className="or">OR</Divider>
            <br />
            {inputs.map((input) => (
              <FormInput
                className="input-email-password input-style"
                key={input.id}
                {...input}
                value={values[input.name as keyof typeof values]}
                onChange={onChange}
              />
            ))}
            <button
              type="button"
              className="button-style"
              onClick={handleCreateAccount}
            >
              Create Account
            </button>
            <p className="signup-page-text alreadyRegistered_text">
              Already have an account?&nbsp;
              <a href="/SignIn">Log In</a>
            </p>

            <p className="signup-page-text viewTerms_text ">
              By creating an account, you agree to our &nbsp;
              <a href="/privacy-policy">Terms and Conditions</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
const SignUp = () => (
  <GoogleOAuthProvider clientId="996633909190-2nnsu9ssi3kmjtkct4dsvt9e7hdgn1fj.apps.googleusercontent.com">
    <SignUpContent />
  </GoogleOAuthProvider>
);

export default SignUp;
